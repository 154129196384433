<template>
  <b-card>
    <validation-observer
      ref="accountSettings"
      #default="{invalid}"
    >
      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <b-avatar
            ref="previewEl"
            :src="avatarPreview || currentAvatarUrl"
            :text="avatarText(userData.lastName + ' ' + userData.firstName)"
            variant="light-primary"
            size="80px"
            rounded
          />
        <!--/ avatar -->
        </b-media-aside>

        <b-media-body
          class="mt-75 ml-75"
        >
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            Wgraj zdjęcie
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @change="uploadAvatar"
          />
          <!--/ upload button -->

          <!-- reset -->
          <b-button
            v-if="avatarPreview || currentAvatarUrl"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="clearAvatar"
          >
            Usuń zdjęcie
          </b-button>
          <!--/ reset --><br>
          <b-card-text>Możliwość wgrania zdjęcia w formacie JPG, GIF i PNG. Maksymalny rozmiar pliku to 800kB</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->

      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="submit"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Nazwisko"
              label-for="lastName"
            >
              <validation-provider
                #default="{errors}"
                rules="required"
              >
                <b-form-input
                  id="lastName"
                  v-model="userDataLocal.lastName"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Imię"
              label-for="firstName"
            >
              <validation-provider
                #default="{errors}"
                rules="required"
              >

                <b-form-input
                  id="firstName"
                  v-model="userDataLocal.firstName"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{errors}"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  type="email"
                  disabled
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="invalid || loading"
              type="submit"
            >
              Zapisz zmiany
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Anuluj
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import AvatarMixin from '@/mixins/avatarMixin'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [AvatarMixin],
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean, default: false,
    },
  },
  data() {
    return {
      userDataLocal: JSON.parse(JSON.stringify(this.userData)),
      profileFile: null,
      required,
      email,
    }
  },
  methods: {
    resetForm() {
      this.userDataLocal = JSON.parse(JSON.stringify(this.userData))
    },
    submit() {
      this.$refs.accountSettings.validate().then(valid => {
        if (valid) {
          this.$emit('submit', this.userDataLocal)
        }
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
