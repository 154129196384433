<template>
  <b-card>
    <validation-observer
      ref="changePassword"
      #default="{invalid}"
    >
      <!-- form -->
      <b-form @submit.prevent="submit">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Stare hasło"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{errors}"
                name="OldPassword"
                vid="OldPassword"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' :null"
                >
                  <b-form-input
                    id="account-old-password"
                    v-model="oldPassword"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
        <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="Nowe hasło"
            >
              <validation-provider
                #default="{errors}"
                name="NewPassword"
                vid="NewPassword"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' :null"
                >
                  <b-form-input
                    id="account-new-password"
                    v-model="newPassword"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Powtórz nowe hasło"
            >
              <validation-provider
                #default="{errors}"
                name="RepeatPassword"
                vid="RepeatPassword"
                rules="required|confirmed:NewPassword"
                :custom-messages="{
                  confirmed: 'Podane hasła różnią się.'
                }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' :null"
                >
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="repeatPassword"
                    :type="passwordFieldTypeRepeat"
                    name="retype-password"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              :disabled="invalid || loading"
              type="submit"
            >
              Zapisz zmiany
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Anuluj
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean, default: false,
    },
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRepeat: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRepeat === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRepeat = this.passwordFieldTypeRepeat === 'password' ? 'text' : 'password'
    },

    submit() {
      this.$refs.changePassword.validate().then(valid => {
        if (valid) {
          this.$emit('submit', { oldPassword: this.oldPassword, newPassword: this.newPassword })
        }
      })
    },
  },
}
</script>
