<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Dane podstawowe</span>
      </template>

      <account-setting-general
        v-if="userData"
        :user-data="userData"
        :loading="loading"
        @submit="submitUserData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Zmień hasło</span>
      </template>

      <account-setting-password
        v-if="userData"
        :user-data="userData"
        :loading="loading"
        @submit="changePassword"
      />
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
  },

  data() {
    return {
      loading: false,
    }
  },
  computed: {
    userData() {
      return this.$store.state.auth.userData
    },
  },
  beforeCreate() {
    this.$store.dispatch('auth/fetchUserData')
  },
  methods: {
    submitUserData(event) {
      const payload = {
        firstName: event.firstName,
        lastName: event.lastName,
        image: event.image,
      }

      this.loading = true
      this.$store
        .dispatch('auth/editUserData', { payload })
        .then(response => {
          if (response && response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Zmieniono dane konta',
                icon: 'UserCheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Nie udało się zmodyfikować danych konta.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: response ? response.data['hydra:description'] : null,
              },
            })
          }
        }).finally(() => {
          this.loading = false
        })
    },

    changePassword(event) {
      const authPayload = {
        email: this.userData.email,
        password: event.oldPassword,
      }
      this.loading = true
      this.$store.dispatch('auth/login', authPayload).then(authResponse => {
        if (authResponse && authResponse.status === 204) {
          const passwordPayload = {
            password: event.newPassword,
          }

          this.$store.dispatch('auth/changePassword', passwordPayload).then(passwordResponse => {
            if (passwordResponse && passwordResponse.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hasło zostało zmienione.',
                  icon: 'KeyIcon',
                  variant: 'success',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Błąd.',
                  text: `Nie udało się zmienić hasła: ${passwordResponse}` ? passwordResponse['hydra:description'] : null,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            }
            this.loading = false
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Błąd autoryzacji.',
              text: 'Poprzednie hasło jest niewłaściwe.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
</script>
